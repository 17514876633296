<script lang="ts" setup>
import Notification from "@frontend/ui-kit/src/components/Base/Notification.vue";
import Footer from "~/components/shared/Common/Footer.vue";
import { useBooking } from "~/composables/booking/useBooking";
import Header from "~/layers/booking/components/shared/common/Header.vue";
import { useNotificationStore } from "~/stores/alert/useNotificationStore";
import ContinueBookingDialog from '~/components/booking/ContinueBookingDialog.vue';
import type { BookingStatus } from "~/types/booking/booking";
import { ROUTES } from "~/constants/routes";

const store = useNotificationStore();

defineProps({
    isError: { type: Boolean, default: false },
});

const { getActiveBookings, getButtonLink, cancelBooking } = useBooking();
const route = useRoute();

const activeBookingId = ref<number | undefined>(undefined);
const isExistActiveBooking = ref<boolean>(false);

onMounted(async () => {
    // Проверяем активные бронирования, если больше 2-х, то редиректим на список.
    const result = await getActiveBookings();
    if(!result.isError) {
        let activeBookings = result.data ?? [];

        // Если в урле есть id, то удаляем его из списка активных бронирований, т.к. мы уже на нем и переходить на него не надо.
        if (route.params.id && import.meta.client) {
            const currentBookingId = Number(route.params.id);
            activeBookings = activeBookings.filter(item => item != currentBookingId) ?? [];
        }

        if(activeBookings.length === 1) {
            activeBookingId.value = activeBookings[0];
            isExistActiveBooking.value = true;
        }
    }
});

const onContinueBooking = async (id: number, status: BookingStatus) : Promise<void> => {
    // Отменяем текущее бронирование.
    if (route.params.id && import.meta.client) {
        const currentBookingId = Number(route.params.id);
        await cancelBooking(currentBookingId);
    }

    // Переходим к тому, которое решили продолжить
    isExistActiveBooking.value = false;
    window.location.href = `${ROUTES.booking_id(id)}?step=${getButtonLink(status)}`;
}
</script>

<template>
    <v-app class="app">
        <Header />
        <v-layout class="layout">
            <div v-if="!isError" class="main">
                <slot />
                <Footer />
            </div>
            <div v-else class="main-container">
                <slot />
            </div>
            <Notification :messages="store.messages"/>
            <ContinueBookingDialog
                v-if="isExistActiveBooking"
                v-model="isExistActiveBooking"
                :bookingId="activeBookingId"
                @continue="onContinueBooking"
            />
        </v-layout>
    </v-app>
</template>

<style scoped lang="scss">
.app {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 1 0 auto;
    background-color: $color-grey-100;

    :deep(.v-application__wrap) {
        justify-content: center;
        align-items: center;
        align-self: stretch;
    }
}

.layout {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 1 0 auto;

    @include responsive("tablet") {
        flex-direction: column;
    }
}

.main {
    display: flex;
    padding: 24px;

    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    gap: 32px;
    flex: 1 0 0;

    @include responsive("phone") {
        padding: 24px 0;
    }
}

.main-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
    width: 100%;
    padding: 40px 80px;
    border-radius: 32px;
    background: $main-card-color;
    box-shadow: $box-shadow-04;

    @include responsive("laptop") {
        padding: 24px 32px;
    }

    @include responsive("desktop-small") {
        padding: 40px;
    }
    
    @include responsive("tablet") {
        padding: 32px 0;
    }
}
</style>
